import jQuery from 'jquery'

window.jQuery = window.$ = require('jquery');

//bootstrap
import bootstrap from 'bootstrap/js/src/index'

//slick
// import slick from 'slick-carousel/slick/slick'

//STYLE
import style from './index.scss'

//custom scripts

$(document).ready(function() {
    $('.rental__list li').mouseenter(function() {
        $(this).siblings('li').removeClass('active');
        $(this).addClass('active');

        let el = $(this).attr('data-target');
        $('.rental__item').css('display', 'none');
        $('.rental__item#' + el).css('display', 'block');
    })

    $('.attraction__link').mouseenter(function() {
        $(this).siblings('.attraction__link').removeClass('active');
        $(this).addClass('active');

        let el = $(this).attr('data-target');
        $('.attraction__item').css('display', 'none');
        $('.attraction__item#' + el).css('display', 'block');
    })
})
